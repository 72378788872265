.container {
  display: flex;
  flex-direction: column;
  padding-left: 2%;
  padding-right: 2%;
  justify-content: space-around;
  margin-right: 2%;
  margin-left: 2%;
  border: solid 10px transparent;
  border-radius: 30px;
  background:
      linear-gradient(#ffffff8e, #fff0cb8e) padding-box,
      linear-gradient(#ffb7008e, #feb7c88e) border-box;
  align-items: center;
}
.icon {
  font-size: 2.5rem;
  margin-right: 5px
}
.table {
  /* display: flex; */
  /* justify-content: center; */
    /* width: 100%; */
    margin-bottom: 20px;
    border: 5px solid #fff;
    border-top: 5px solid #fff;
    border-bottom: 3px solid #fff;
    border-collapse: collapse; 
    outline: 3px solid var(--primary-color);
    font-size: 15px;
    background: #fff!important;
}
.cell{
  display: flex;
  flex-direction: row;
}
.icon {
  font-size: 2.5rem;
  margin-right: 5px
}
.tableth {
    font-weight: bold;
    padding: 7px;
    background: var(--primary-color);
    border: none;
    text-align: left;
    font-size: 15px;
    border-top: 3px solid #fff;
    border-bottom: 3px solid var(--primary-color);
}
.tabletd {
    padding: 7px;
    border: none;
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
    font-size: 15px;
}
.table tbody tr:nth-child(even){
    background: #f8f8f8!important;
}
.container_form {
  /* width: 90%; */
  display: flex;
  justify-content: center;
  align-items: center;

  /* padding: 5% 15px */
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 500px; */
  margin: 20px auto;
  justify-content: center;
}
.border{
  width: 80%;
display: flex;
flex-direction: column;
  /* margin:1%; */
  border: solid 10px transparent;
  border-radius: 30px;
  /* width: 44vw;
  height: 25vw; */
      background:
      linear-gradient(#ffffff, #fff0cb) padding-box,
      linear-gradient(#ffb700, #feb7c8) border-box;
  align-items: center;
  display: flex;
  flex-direction: column;

  justify-content: center;
}
.table {
    /* display: flex; */
    /* justify-content: center; */
    /* width: 100%; */
    overflow-x: auto;

    margin-bottom: 20px;
    border: 5px solid #fff;
    border-top: 5px solid #fff;
    border-bottom: 3px solid #fff;
    border-collapse: collapse;
    outline: 3px solid var(--primary-color);
    font-size: 15px;
    background: #fff !important;
}

.borders {
    /* margin-right: 10%;
    margin-left: 10%; */
    width: 89vw;
height: 50vw;
    border: solid 10px transparent;
    border-radius: 30px;
    background:
        linear-gradient(#ffffff, #fff0cb) padding-box,
        linear-gradient(#ffb700, #feb7c8) border-box;
    align-items: center;
}




.pie {
    margin:1%;
    border: solid 10px transparent;
    border-radius: 30px;
    width: 44vw;
    height: 25vw;
        background:
        linear-gradient(#ffffff, #fff0cb) padding-box,
        linear-gradient(#ffb700, #feb7c8) border-box;
    align-items: center;
    display: flex;

    justify-content: center;
}
.pies {
    display: flex;
    flex-direction: row;
}
@media (max-width: 991.98px) {
    .pies {
        flex-direction: column;
    }

    .pie {
        width: 89vw;
        height: 50vw;
            }
    .bordershalf {
        /* margin-right: 10%;
        margin-left: 10%; */
        width: 89vw;
        height: 50vw;
            }
}

.icon {
    font-size: 2.5rem;
    margin-right: 5px
}

.tableth {
    font-weight: bold;
    padding: 7px;
    background: var(--primary-color);
    border: none;
    text-align: left;
    font-size: 15px;
    border-top: 3px solid #fff;
    border-bottom: 3px solid var(--primary-color);
}

.tabletd {
    padding: 7px;
    border: none;
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
    font-size: 15px;
}

.table tbody tr:nth-child(even) {
    background: #f8f8f8 !important;
}
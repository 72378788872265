.loading {
  /* width: 100vw;
  max-width: 100vw; */
  margin: 0 auto;
  height: 100vh;
  /* display: flex; */
  flex-direction: column;
  /* align-items: center; */
  background: url('https://api.kotopes39.ru/static/newback.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  /* background-color: #04b31b; */
  /* justify-content: center; */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  /* background-color: rgba(34, 34, 34, 0.527); */
  top: 0;
  left: 0;
}

.text {
  letter-spacing: 0.8rem;
  color: rgba(0, 0, 0, 0.719);
  position: relative;
}

.text::after {
  content: "|";
  position: absolute;
  right: 0;
  width: 100%;
  color: rgb(153, 58, 58);
  background: #f5d447;
  /* -webkit-animation: typing 1s forwards, caret 0.5s infinite;
          animation: typing 1s  forwards, caret 0.5s infinite; */
}
@media (max-width: 991.98px) {


  .loading {
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background: url('https://api.kotopes39.ru/static/newback2.png');
  }
}
@-webkit-keyframes typing {
  to {
    width: 0;
  }
}
@-webkit-keyframes caret {
  50% {
    color: transparent;
  }
}

@keyframes typing {
  to {
    width: 0;
  }
}
@keyframes caret {
  50% {
    color: transparent;
  }

}
.modal_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: rgba(34, 34, 34, 0.527);
 
top: 0;
left: 0;
}
.container_modalcart {
  width: 650px;
  height: 480px;
  /* margin-right: 100px; */
  background: rgb(255, 255, 255);
  position: absolute;
  right: 50px;
  border-radius: 10px;
  clip-path: polygon(0 100%, 100% 100%, 100% 6%, 81% 6%, 78% 1%, 75% 6%, 0 6%);
  z-index: 666;
}

.container_modalcart_emty {
  width: 300px;
  height: 150px;
  /* margin-right: 100px; */
  background: rgb(255, 255, 255);
  position: absolute;
  right: 50px;
  border-radius: 10px;
  clip-path: polygon(0 100%, 100% 100%, 100% 6%, 81% 6%, 78% 1%, 75% 6%, 0 6%);
  z-index: 666;
}
.container_produtos {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 350px;
}
.container_modalinfos {
  margin: 0 auto;
  width: 95%;
  height: 200px;
  padding-top: 50px;
  justify-content: space-around;
  border-bottom: 1px solid rgba(0, 0, 0, 0.151);
  display: flex;
  align-items: center;
}
.container_infos {
  display: flex;
  flex-direction: column;
  height: 80px;
  /* justify-content: flex-end; */
  width: 50%;
  /* align-items: flex-end; */
  justify-content: space-around;}
.produto_desc {
  font-size: 0.8rem;
  font-weight: 700;
}
.produto_nome {
  font-size: 0.7rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.425);
}
.produto_preco {
  font-size: 0.9rem;
  font-weight: 700;
  color: var(--primary-color);
}
.container_image {
  width: 20%;
  /* height: 150px; */
}
.container_image img {
  --height: 100px;
  width: var(--height);
  height: auto;
}
/* .ButtonsModal {
  margin-right: 50px;
} */

.total_container {
  width: 100%;
  padding: 0 50px;
  border-top: 1px solid rgba(0, 0, 0, 0.151);
}
.iconRemove {
  font-size: 1.5rem;
  color: red;
  cursor: pointer;
}
.total {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  font-size: 1rem;
  font-weight: 500;
}


@media (max-width: 991.98px) { 
  .container_modalcart {
    width: 80%;
    /* margin: 15px; */
    position: absolute;
    /* right: -20px; */
    clip-path: polygon(0 100%, 100% 100%, 100% 6%, 96% 6%, 92% 1%, 88% 6%, 0 6%)
  }
  .total_container {
    padding: 0 30px;
  }
  .container_infos {
    justify-content: space-evenly;
    width: 40%;
   
  }
}
.container_geral_prod {
  background: url('../../Assets/paw.svg');
  background-repeat: no-repeat;
  background-size: 20%;
}

.imgdiv {
  display: flex;
  justify-content: center;
  width: 80%
}

.btns {
  display: flex;
  flex-direction: column;
  /* width: 90%; */
  align-items: center;

}

.descricao {
  width: 700px;
}

.icon {
  font-size: 3rem;
  margin-right: 5px
}

.back {
  display: flex;
  justify-content: center;
  /* width: 30px; */
  flex-direction: row;
  margin-left: 25%;
  align-content: center;
  align-items: center;
  padding: 5px;
  margin-left: 20%;
  margin: 5px;
  border: solid var(--primary-color);
  width: fit-content;
  border-radius: 25%;
}

.btnsback {
  width: 80%;
  display: flex;
  justify-content: center;
  /* width: 30px; */
  flex-direction: row;
  /* margin-left: 25%; */
  align-content: center;
  align-items: center;
}

.conten {
  display: 'flex';
  justify-content: center;
  flex-direction: 'column';
  /* margin-right: 10%;
  margin-left: 10%; */
  border: solid 10px transparent;
  border-radius: 30px;
  background:
    linear-gradient(#ffffff, #fff0cb) padding-box,
    linear-gradient(#ffb700, #feb7c8) border-box;
  align-items: center;
}

.slidecontainermain {
  display: flex;
  justify-content: center;
  width: 50%;
  height: 100%;

}

.container_infos {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 20px;
}

.produtos_container {
  width: 1100px;
  display: grid;
  justify-content: center;
  margin-left: 100px;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin: 0 auto;
}

.scale {
  /* height: 500px; */
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}

.price {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 991.98px) {
  .produtos_container {
    width: 85vw;
    grid-template-columns: repeat(1, 1fr);
  }

  .slidecontainermain {

    width: 90%;
  }

  .borders {
    margin-right: 5%;
    margin-left: 5%;
  }

  .container_infos {
    flex-direction: column;
    width: 100%;
  }

  .conten {
    width: 100%;
  }

  /* .imgdiv{ 
    width:100%
  } */
}
.menu_container {
  display: flex;
  align-items: center;

}
/* .ul { */
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 1.1rem;
  margin-right: 20px;
  text-transform: uppercase; */
/* } */
.ulmobitem {
  flex: 1 0 100%;
  align-items: center;
  justify-content: space-between;
}

.ulmobitem:nth-child(n+2):nth-child(-n+10) {
  flex: 1 0 48%;
  margin: 1%;
}
.ulmobile{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
a {
  color: white;
  margin-right: 25px;
  transition: all .3s;
}
a:hover {
  color: #f3d097;
}

/* @media (max-width: 991.98px) { */
  .ul {
    display: none;
  }
  .cart {
    display: none;
  }
  .menu_container {
    display: flex;
    align-items: center;
    
  ;
  
  }
/* } */
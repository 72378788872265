.table {
    /* display: flex; */
    /* justify-content: center; */
      /* width: 100%; */
      margin-bottom: 20px;
      border: 5px solid #fff;
      border-top: 5px solid #fff;
      border-bottom: 3px solid #fff;
      border-collapse: collapse; 
      outline: 3px solid var(--primary-color);
      font-size: 15px;
      background: #fff!important;
  }
  .uploaddiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    background: white;
    border-radius: 15px;
    padding: 20px;
  }
  .icon {
    font-size: 2.5rem;
    margin-right: 5px
  }
  .inputdiv{
    height: 100px;
    width: 500px;
  }
  @media (max-width: 991.98px) {
    .inputdiv{
      width: 100%;
    }
  }
  
  .p {
    width: 80%;
    text-align: center;
    margin: 20px auto
  }
  .img {
    /* width: 100%; */
    height: 100%;
    cursor: pointer;
    padding: 8px;
    margin: 0 auto;
    object-fit: contain;
    border-radius: 30px;
    transition: all .3s;
  }
  .imgcont{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50%;
  }
  .cards_produtos {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    padding-top: 15px;
    /* padding: 25px; */
    flex-direction: column;
    text-align: center;
    /* background: rgba(206, 201, 201, 0.444); */
    background: rgba(255, 255, 255, 0.75);
    border-radius: 10px;
  }
  
  .cards_produtos p {
    font-weight: bold;
    margin: 5px 0;
  }
  .tableth {
      font-weight: bold;
      padding: 7px;
      background: var(--primary-color);
      border: none;
      text-align: left;
      font-size: 15px;
      border-top: 3px solid #fff;
      border-bottom: 3px solid var(--primary-color);
  }
  .tabletd {
      padding: 7px;
      border: none;
      border-top: 3px solid #fff;
      border-bottom: 3px solid #fff;
      font-size: 15px;
  }
  .table tbody tr:nth-child(even){
      background: #f8f8f8!important;
  }
  .container_form {
    width: 100%;
    display: flex;
    justify-content: center;
    /* padding: 5% 15px */
  }
  
  .form {
    display: flex;
    flex-direction: column;
    width: 600px;
    /* height: 500px; */
    margin: 20px auto;
    justify-content: center;
  }
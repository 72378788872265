.cart_container {
  position: relative;
  z-index: 77;
}
.cart {
  font-size: 1.6rem;
  background-color: rgb(252, 222, 138);
  cursor: pointer; 
}


.cart:hover {
  color: var(--primary-color);
}

.cart_total {
  width: 25px;
  height: 25px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem !important;
  right: -16px;
  background-color: rgb(255, 255, 255);
  border-radius: 200px;
  color: red;
  top: -23px;
}
.container_form {
  width: 90%;
  display: flex;
  justify-content: center;
  /* padding: 5% 15px */
}
.adress{
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

}
.border {
  margin-right: 10%;
  margin-left: 10%;
  border: solid 10px transparent;
  border-radius: 30px;
  background:
      linear-gradient(#ffffff, #fff0cb) padding-box,
      linear-gradient(#ffb700, #feb7c8) border-box;
  align-items: center;
}
.form_radio_btn {
	display: inline-block;
	margin-right: 10px;
}
.form_radio_btn input[type=radio] {
	display: none;
}
.form_radio_btn label {
	display: inline-block;
	cursor: pointer;
	padding: 0px 15px;
	line-height: 34px;
	border: 1px solid #999;
	border-radius: 6px;
	user-select: none;
}
 
/* Checked */
.form_radio_btn input[type=radio]:checked + label {
	background: #ffe0a6;
}
 
/* Hover */
.form_radio_btn label:hover {
	color: #666;
}
 
/* Disabled */
.form_radio_btn input[type=radio]:disabled + label {
	background: #efefef;
	color: #666;
}
.radiopay{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 20px auto;
  width: 40%;
}
.radiobtn{
  margin-left: 20px;
  margin-right: 20px;
}
.adress1{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.adress2{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.adressInput{
  display: flex;
flex-direction: column;
width: 300px;
align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  width: 600px;
  /* height: 500px; */
  margin: 20px auto;
  justify-content: center;
}
textarea {
  resize: none
}

@media (max-width: 991.98px) { 
  .plaska {
    width: 80%;
  }
}
.plaska{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 40%;
    margin: 20px auto;
    /* justify-content: center; */
}

.plaskaitem{
  height: 50px;
  width: 50px;
  /* padding: 1%; */
  border-radius: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.promodiv{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bonuses{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.container_pages{
  display: flex;
  align-items:center;
  flex-direction: column;
  justify-content: center;
}
.container_pages3{
  display: flex;
  align-items:center;
  flex-direction: column;
  justify-content: center;
}
.container_pages4{
  display: flex;
  align-items:center;
  flex-direction: row;
  justify-content: center;
}
.pagesbuttons{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.p {
  width: 90%;
  /* height: 50px; */
  text-align: center;
  margin: 20px auto
}
.gth{
  display: flex;
  align-items: center;
justify-content: center;
flex-direction: row;
justify-content: center;
}
input {
  width: 80%;
  height: 50px;
}
select {
  width: 80%;
  height: 50px;
}
/* 
.table.container {
  display: flex;
  flex-direction: column;
} */
.container_table1{
  /* width: 90%; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* overflow-x: auto; */
  /* padding: 5% 5px; */
  /* flex-direction: column; */

}
.container_table{
  width: 90%;
  display: flex;
  justify-content: center;
  padding: 5% 5px;
  /* flex-direction: column; */

}
.table {
  /* display: flex; */
  /* justify-content: center; */
	width: 100%;
	margin-bottom: 20px;
	border: 5px solid #fff;
	border-top: 5px solid #fff;
	border-bottom: 3px solid #fff;
	border-collapse: collapse; 
	outline: 3px solidvar(--primary-color);
	font-size: 15px;
	background: #fff!important;
}
.tableth {
	font-weight: bold;
	padding: 7px;
	background: var(--primary-color);
	border: none;
	text-align: left;
	font-size: 15px;
	border-top: 3px solid #fff;
	border-bottom: 3px solid var(--primary-color);
}
.tabletd {
	padding: 7px;
	border: none;
	border-top: 3px solid #fff;
	border-bottom: 3px solid #fff;
	font-size: 15px;
}
.table tbody tr:nth-child(even){
	background: #f8f8f8!important;
}
.radiocont{
display: flex;
flex-direction: column;
justify-content: space-between;

}
.radiocont2{
display: flex;
flex-direction: row;
}

.radioinput{
  height: 30px;
width: 50%;
}
@media (max-width: 991.98px) { 
  .border {
    margin-right: 2%;
    margin-left: 2%;
  }
  .plaska{
    width: 80%;
}
}
.wrapper{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.993);
  clip-path: circle(0px at calc(100% - 400px) 45px);
  transition: all 0.3s ease-in-out; 
}


#active:checked ~ .wrapper{
  clip-path: circle(75%);
}
.menu_btn{
  display: none;
  z-index: 2;
  /* height: 50px;  */
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
#active:checked ~ .menu_btn{
  color: #fff;
}

.wrapper ul{
  width: 100vw;
  height: 100vh;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
}

.wrapper ul a{
  text-decoration: none;
  margin: 15px 0;
  font-size: 30px;
  font-weight: 500;
  position: relative;
  line-height: 50px;
  transition: all 0.3s ease; 
}
.wrapper ul a:hover{
  color: var(--primary-color);
}
input[type="checkbox"]{
  display: none;
}


@keyframes rotate {
  0%{
    filter: hue-rotate(0deg);
  }
  100%{
    filter: hue-rotate(360deg);
  }
}
.container_menu {  
  position: fixed;
  z-index: 999;
  background: rgba(66, 55, 49, 0.41);
  width: 100vw;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 10px; */
  padding: 0 30px;
}

/* .menu {
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer; 
} */

#bar,
#bar:after,
#bar:before {
  width: 30px;
  height: 3px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.664);
}

#bar {
  position: relative;
  background: rgb(255, 255, 255);
  transition: all 0ms 300ms;
}
#bar::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 10px;
  background: rgb(255, 255, 255);
  transition: bottom 0.3s 0.3s cubic-bezier(0.23, 1, 0.32, 1), transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
#bar::after {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  background: rgb(255, 255, 255);
  transition: top 0.3s 0.3s cubic-bezier(0.23, 1, 0.32, 1), transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
#bar.animate {
  background: rgba(255, 255, 255, 0);
}
#bar.animate::after {
  top: 0;
  transform: rotate(45deg);
  transition: top 0.3s cubic-bezier(0.23, 1, 0.32, 1), transform 0.3s 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
#bar.animate::before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 0.3s cubic-bezier(0.23, 1, 0.32, 1), transform 0.3s 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}


/* @media (max-width: 991.98px) { */
  .wrapper, .menu_btn {
    display: block;
  }
  .menu {
    display: flex;
  }
/* } */
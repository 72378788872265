.footer {
  margin-top: 5%;
display: flex;
width: 80%;
align-items: center;
border-radius: 30px 30px 0 0;
  /* width: 100%; */
  height: 400px;
  background: var(--primary-color);
  /* background: #20b6e8; */
  /* display: flex; */
  justify-content: space-between; 
  /* clip-path: polygon(50% 19%, 51% 19%, 100% 0, 100% 70%, 100% 100%, 30% 100%, 0 100%, 0 0); */
}

.img {
  height: 100%;
  padding-top: 100px;
  margin: 0 50px;
}

.footer_logo {
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.container_ul {
  display: flex;
  width: 100%;
  justify-content: space-between
}
.ul {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  font-weight: 600;
}
.ul h3 {
  /* color: var(--primary-color); */
  margin-bottom: 20px;
}
.ul a {
  margin-bottom: 10px;
}
.icon {
  font-size: 2rem;
  margin-right: 10px
}
.ul a:hover {
  color: var(--primary-color);
}

.ul p {
  color: white;
  width: 200px;
}

@media (max-width: 1367px) {
  .footer {
    height: 350px;
  }
  .img {
    margin: 0 10px;
  }

}
@media (max-width: 991.98px) {
  .footer {
    height: auto;
  }
  .img {
    display: none;
  }
  .container_ul {
    flex-direction: column;
  }
  
  .ul {
    align-items: center;
    text-align: center;
  }

  .icon {
    font-size: 2rem;
    margin-right: 0px
  }
  
}
.btn {
  width: 200px;
  height: 50px;
  background: var(--primary-color);
  cursor: pointer;
  border: none;
  border-radius: 7px;
  color: rgba(0, 0, 0, 0.473);
  text-transform: uppercase;
  font-weight: bold;
  transition: all .3s;
  margin: 10px;
}

.btn:hover {
  background: #e7b660;
  color: white;
  box-shadow: .2px .2px 2px 1px rgba(255, 255, 255, 0.87);
}


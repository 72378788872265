.table {
    /* display: flex; */
    /* justify-content: center; */
    /* width: 100%; */
    overflow-x: auto;

    margin-bottom: 20px;
    border: 5px solid #fff;
    border-top: 5px solid #fff;
    border-bottom: 3px solid #fff;
    border-collapse: collapse;
    outline: 3px solid var(--primary-color);
    font-size: 15px;
    background: #fff !important;
}


.pies {
    display: flex;
    flex-direction: row;
    align-items: center;
}



.pie {
    width: 50%;
    display: flex;
    width: 50%;
    justify-content: center;
}
@media (max-width: 991.98px) {
    .pies {
        flex-direction: column;
    }

    .pie {
        width: 100%
    }
}
.icon {
    font-size: 2.5rem;
    margin-right: 5px
}

.tableth {
    font-weight: bold;
    padding: 7px;
    background: var(--primary-color);
    border: none;
    text-align: left;
    font-size: 15px;
    border-top: 3px solid #fff;
    border-bottom: 3px solid var(--primary-color);
}

.tabletd {
    padding: 7px;
    border: none;
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
    font-size: 15px;
}

.table tbody tr:nth-child(even) {
    background: #f8f8f8 !important;
}
.container {
    display: flex;
    flex-direction: column;
    padding-left: 5%;
    padding-right: 5%;
}
.info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    overflow-x: auto;
}
.info2 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  overflow-x: auto;
}

.fbta{
display: flex;
align-items: center;
resize: none;
height: 100px;
width: 90%;
}
.card {
    background: #fff;
border: 1px solid #d0d8db;
border-radius: 3px;
/* margin: 20% auto 0; */
overflow: hidden;
position: relative;
height: fit-content;
width: 270px;
  }
  .card h1,
  .card p {
    margin: 0;
  }
  .content {
    border-left: 4px solid transparent;
    padding: 10px 10px 5px;
    padding: 20px;
  }
  .content.active {
    border-color: #f8a822;
  }
  .title {
    font-size: 1.2em;
    font-weight: 600;
    
    line-height: 1.2;
    color: 'var(--primary-color)'
  }
  .title p {
    color: #7e909e;
    font-weight: 500;
    font-size: 0.7rem;
    line-height: 2;
    margin-top: 4px;
  }
  .meta {
    border-top: 1px solid #d0d8db;
    color: #7e909e;
    display: block;
    font-size: 0.7rem;
    font-weight: 500;
    line-height: 2;
    margin: 5px 0 0;
    padding: 5px 0 0;
  }
  .feedback {
    align-items: center;
    background: #f7f7fb;
    bottom: -100%;
    border-top: 1px solid #fff;
    color: #3c4858;
    display: flex;
    font-size: 0.7rem;
    font-weight: 600;
    left: 0;
    line-height: 32px;
    opacity: 0;
    padding: 0 15px;
    position: absolute;
    right: 0;
    transition: all 300ms ease;
  }
  
  .actions {
    flex: 0 1 auto;
    justify-items: right;
  }
  .actions .yes,
  .actions .no {
    color: #7e909e;
    cursor: pointer;
    margin-left: 10px;
    transition: color 200ms ease;
  }
  .actions .yes:hover,
  .actions .no:hover {
    color: #00a3e0;
  }
  .card:hover .feedback {
    bottom: 0;
    opacity: 1;
  }
  
.modal_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  /* opacity: 0.7; */
  background-color: rgba(126, 125, 125, 0.527);
  top: 0;
  left: 0;
}

.modal {
  width: 60%;
  /* height: 60%; */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(255, 255, 255);
  /* opacity: 1; */

  border-radius: 10px;
}

.modal img{
  width: 80%;
  /* height: 100%; */
}

.container_infos {
  /* width: 300px; */
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-around;
  /* flex-wrap: wrap; */
}
.title {
  margin-top: 20px;
  margin-bottom: 20px;
}
.container_price {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.p {
  font-size: 2rem;
}
.price span {
  font-size: 1.1rem;
  color: var(--primary-color);
}
.descricao {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.btns {
  display: flex;
  justify-content: center;
  margin: 60px 0;
}

@media (max-width: 991.98px) {
  .modal {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 90%;
  }
  .container_infos {
    height: 95%;
    overflow-y: auto;
    display: flex;
    width: 90%;
    flex-direction: row;
    flex-wrap: wrap;


  }
  .descricao {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    text-align: center;
  }
  .container_price {
    margin-bottom: 20px;
  }
  .title {
    /* display: flex;
    flex-direction: column; */
    font-size: 0.7rem;
    width: 85vw;
    margin: 20px auto;
    text-align: center;
  }
  .p {
    font-size: 1.1rem;
  }
.modal img {
  margin-top: 10px;
  margin-bottom: 20px;

}
.btns {
  width: 100%;
  padding: 10px;
  margin: 0;
}
}
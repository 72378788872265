.link {
  font-weight: bold;
  color: var(--primary-color);
}
.link:hover {
  font-weight: bold;
  color: orange;
}
.test {
  display: flex;
  justify-content: center;
}
.p {
  width: 400px;
  margin-bottom: 15px;
  margin-left: 15px;
}

@media (max-width: 991.98px) { 
  .p {
    width: 85vw;
    text-align: center;
  }
}
.img {
  /* width: 100%; */
  height: 100%;
  cursor: pointer;
  padding: 8px;
  margin: 0 auto;
  object-fit: contain;
  border-radius: 30px;
  transition: all .3s;
}
.imgcont{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50%;
}
.img:hover {
  transform: scale(1.1);
}

.produtos_container {
  width: 90%;
  display: grid;
  justify-content: center;
  margin-left: 100px;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin: 0 auto;
}
.produtos_containersuh {
  width: 90%;
  display: grid;
  justify-content: center;
  margin-left: 100px;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin: 0 auto;
}

.cards_produtos {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  padding-top: 15px;
  /* padding: 25px; */
  flex-direction: column;
  text-align: center;
  /* background: rgba(206, 201, 201, 0.444); */
  background: rgba(255, 255, 255, 0.75);
  border-radius: 10px;
}

.cards_produtos p {
  font-weight: bold;
  margin: 5px 0;
}

.price {
  color: var(--primary-color);
  font-weight: bold;
}


.vbadgecont {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
}

.vbadge {
  position: relative;
  text-decoration: none;
  padding: 8px 16px;
  color: black;
  font-weight: 500;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  background: linear-gradient(to bottom, #FF0000, #FF0000);
  border-radius: 999px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  user-select: none;
}

.vbadge span {
  width: 25px;
  height: 25px;
  position: absolute;
  top: -12px;
  right: -2px;
  transform: rotate(-20deg);
  filter: blur(0.5px);
}

.vbadge span:before,
.vbadge span:after {
  content: "";
  position: absolute;
}

/* .vbadge span:before {
  width: 1px;
  height: 100%;
  left: 12px;
  background: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.7), transparent);
}
.vbadge span:after {
  width: 100%;
  height: 1px;
  top: 12px;
  background: linear-gradient(to left, transparent, rgba(255, 255, 255, 0.7), transparent);
} */
/* .vbadge:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: linear-gradient(30deg, #FF0000 60%, white);
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  border-radius: 999px;
} */

.borders {
  margin-right: 5%;
  margin-left: 5%;
  border: solid 10px transparent;
  border-radius: 30px;
  background:
    linear-gradient(#ffffff, #fff0cb) padding-box,
    linear-gradient(#ffb700, #feb7c8) border-box;
  align-items: center;

}
.container_price{
  flex-direction: row;
 }
 .vbadge21{
  width: 100px;
 }
@media (max-width: 991.98px) {
  .borders {
    margin-right: 2%;
    margin-left: 2%;
  }
.produtos_containersuh{
  width: 85vw;
  grid-template-columns: repeat(1, 1fr);
  

}
.vbadge21{
  width: 100%;
 }
.vbadge11{
  zoom:1.5
 }
.cards_produtos {
   zoom: 0.5;
}
.doublexoom{
  zoom:2
}

  /* .p {
    width: 80;
  } */
   .container_price{
    flex-direction: column;
    /* zoom:2 */
   }
  .produtos_container {
    height: calc(100% * 0.5);
 
  transform-origin: top;
    width: 85vw;
    grid-template-columns: repeat(2, 1fr);
  
  }
}

.p {
  width: 80%;
  text-align: center;
  margin: 20px auto
}
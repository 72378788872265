.container_geral_prod {
  background: url('../../Assets/paw.svg');
  background-repeat: no-repeat;
  background-size: 20%;
}

.produtos_container {
  /* width: 1100px; */
  display: grid;
  justify-content: center;
  margin-left: 100px;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin: 0 auto;
}

@media (max-width: 991.98px) { 
  .produtos_container {
    width: 85vw;
    grid-template-columns: repeat(1, 1fr);
  }
}
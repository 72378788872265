.container {
    display: flex;
    flex-direction: column;
    padding-left: 5%;
    padding-right: 5%;
}
.info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
/* .container {
    display: flex;
    flex-direction: column;
} */
/* .input{

} */
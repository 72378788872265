.container {
  height: 600px;
  border-radius: 0 0 30px 30px;
  /* background: url('https://api.kotopes39.ru/static//background_tiny.png') no-repeat; */
  background: url('https://api.kotopes39.ru/static/101.png') no-repeat;
  /* background: url('https://api.kotopes39.ru/static/102l.jpg') no-repeat; */
  /* rita */
  /* background: url('https://abrakadabra.fun/uploads/posts/2022-01/thumbs/1641307314_4-abrakadabra-fun-p-oboi-na-telefon-s-kotikami-i-sobachkami-4.jpg') no-repeat; */
  /* background: url('https://img3.akspic.ru/crops/8/0/3/2/32308/32308-usy-sobaka_porody-morda-sobaka-pes-3840x2160.jpg') no-repeat; */
  background-size: cover;
  background-position: 50%;
  background-size: cover;
  /* background-position-y: top; */
}

.containerOrder {
  height: 150px;
  background: url('https://bogatyr.club/uploads/posts/2021-11/1636930119_1-bogatyr-club-p-fon-listya-osen-1.jpg');
  /* background-color:  #04b31b; */
  background-size: cover;
  /* -webkit-clip-path: polygon(0 0, 100% 0, 100% 87%, 50% 94%, 0 88%);
  clip-path: polygon(0 0, 100% 0, 100% 87%, 50% 100%, 0 87%); */
}

.nav_container{
  max-width: 65rem;
  margin: 0 auto;
  padding-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}


.infos {
  max-width: 100%;
  text-align: center;
  margin-top: 200px;
  margin: 75px 0 0 130px;
  /* text-shadow: #20b6e8 2px 2px 0px; */
    color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.infos h2 {
  margin: 1rem 0;
  color: rgba(201, 201, 201, 0.801);
}

@media (max-width: 1367px) {
  .nav_container{
    max-width: 60rem;
  }
  .infos {
    max-width: 80%;
    text-align: center;
    margin: 90px 0 0 130px;
  }
}
@media (max-width: 1441px) {
  .infos {
    max-width: 80%;
    text-align: center;
    margin: 75px 0 0 130px;
  }
}
@media (max-width: 1025px) {
  .infos {
    max-width: 80%;
    text-align: center;

    margin: 75px 0 0 130px;
    font-size: 13px;
  }
  .nav_container{
    max-width: 50rem;
  }
}

@media (max-width: 991.98px) {
  .container {
    height: 400px;
    background-position-y: top;
    background-position: center;
  }
  .containerOrder {
    /* height: 200px; */
    background-color:  var(--primary-color);;
    background-size: cover;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 87%, 50% 94%, 0 88%);
    clip-path: polygon(0 0, 100% 0, 100% 87%, 50% 100%, 0 87%);
  }
  .nav_container{
    max-width: 80rem;
    margin: 0 auto;
    padding-top: 1rem;
  }
  
  .infos {
    max-width: 80%;
    text-align: center;
    margin: 100px 0 0 50px;

  }
  .infos h2 {
    margin: 1rem 0;
    color: rgba(247, 240, 240, 0.836);
  }
  .logo {
    display: none;
  }
}